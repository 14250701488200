import {
  FaInstagram,
  FaFacebookF,
  FaTwitter,
  FaLinkedin,
} from "react-icons/fa";
import { Link } from "react-router-dom";
import logo from "../images/logo.png";
import "./footer.css";

export function Footer() {
  return (
    <div className="footer">
      <div className="f-info container row">
        <section className="f-social-medias col-lg-3 col-sm-3 col-xs-12 mt-2">
          <div className="f-logo-text">
            <Link style={{ display: "flex" }} to="/">
              <div className="f-logo">
                <img src={"./perfect-interior.png"} alt="logo"></img>
              </div>
              <div className="f-text">
                <p>Perfect</p>
              </div>
            </Link>
          </div>
          <div className="f-about-text">
            <p>
            Perfect Interior Shop – Where Your Dream Home Becomes a Reality.
            </p>
          </div>
          <div className="f-smedia">
            <ul>
              <li>
                <a href="https://www.facebook.com/profile.php?id=61563276677372&mibextid=ZbWKwL">
                  <FaFacebookF />
                </a>
              </li>
              <li>
                <a href="https://www.instagram.com/home.interiors2024?utm_source=qr&igsh=MWVweHRucWp0bXg0OQ==">
                  <FaInstagram />
                </a>
              </li>
              {/* <li>
                <a href="https://www.twitter.com/">
                  <FaTwitter />
                </a>
              </li>
              <li>
                <a href="https://www.linkedin.com/">
                  <FaLinkedin />
                </a>
              </li> */}
            </ul>
          </div>
        </section>
        <section className="f-pages col-lg-3 col-sm-3 col-xs-12">
          <p>Pages</p>
          <ul>
            <li>
              <Link to={`/aboutus`}>About Us</Link>
            </li>
            <li>
              <Link to={`/projects`}>Our Projects</Link>
            </li>
            {/* <li>
              <Link to={`/team`}>Our Team</Link>
            </li> */}
            <li>
              <Link to={`/contact`}>Contact Us</Link>
            </li>
            <li>
              <Link to={`/services`}>Services</Link>
            </li>
          </ul>
        </section>
        <section className="f-services col-lg-3 col-sm-3 col-xs-12">
          <p>Services</p>
          <ul>
            <li>
              <Link to={`/serviceSingle`}>Kitchan</Link>
            </li>
            <li>
              <Link to={`/serviceSingle`}>Living Area</Link>
            </li>
            <li>
              <Link to={`/serviceSingle`}>Bathroom</Link>
            </li>
            <li>
              <Link to={`/serviceSingle`}>Dinning Hall</Link>
            </li>
            <li>
              <Link to={`/serviceSingle`}>Bedroom</Link>
            </li>
          </ul>
        </section>
        <section className="f-contact col-lg-3 col-sm-3 col-xs-12">
          <p>Contact</p>
          <p>No.110, PRH Road, Kalpalayam, Kolathur, Chennai - 600099</p>
          <p>
            <a href="mailto: contact@interno.com">perfectinteriorschennai@gmail.com</a>
          </p>
          <p>+(91) 7395970643</p>
        </section>
      </div>
      {/* <div className="terms">
        <ul className="terms-list">
          <li>
            <Link to={`/terms`}>Terms & Conditions</Link>
          </li>
          <li>
            <Link to={`/privacy-policy`}>Privacy Policy</Link>
          </li>
          <li>
            <Link to={`/cookies-policy`}>Cookies Policy</Link>
          </li>
          <li>
            <Link to={`/faq`}>FAQ</Link>
          </li>
        </ul>
      </div> */}
      <div className="f-copyright">
        <p>Copyright © Perfect Interior</p>
      </div>
    </div>
  );
}
