import "./home.css";
import "./homeResponsive.css";
import photoBg from "../images/backgroung/homeBg.jpg";
import { Link } from "react-router-dom";
import { BsArrowRight, BsTelephone } from "react-icons/bs";
import { AiOutlineArrowRight } from "react-icons/ai";
import { IoIosArrowForward } from "react-icons/io";
import services from "../servicesPage/services.json";
import chooseUs from "./chooseUs.json"
import thoughts from "./peopleThoughts.json";
import CountUp from "react-countup";

import home1 from "../images/custom/0179eade-3c5c-4711-98ae-a0c5deeb27dd.jpg";
import hPoject1 from "../images/custom/rack-innovation.jpeg";
import hhProject2 from "../images/custom/30ab81b3-a062-4f42-9087-065f17943945.jpg";
import hhProject3 from "../images/custom/1d9f0ed8-6d8c-4725-bd0e-3792f0faed10.jpg";
import hhProject4 from "../images/custom/ca49cabf-d441-40b9-8ddd-79466165a1b4.jpg";
import hArticle1 from "../images/home/hArticle1.jpg";
import hArticle2 from "../images/home/hArticle2.jpg";
import hArticle3 from "../images/home/hArticle3.jpg";
import brand1 from "../images/brand1.svg";
import brand2 from "../images/brand2.svg";
import brand3 from "../images/brand3.svg";
import brand4 from "../images/brand4.svg";
import brand5 from "../images/brand5.svg";
import customer1 from "../images/home/customer.jpg";
import services1 from '../images/services1.jpg';
import services2 from '../images/services2.jpg';
import services3 from '../images/services3.jpg';
import services4 from '../images/services4.jpg';
import servicesIcon1 from '../images/serviceIcon/servicesIcon1.png';
import servicesIcon2 from '../images/serviceIcon/servicesIcon2.png';
import servicesIcon3 from '../images/serviceIcon/servicesIcon3.png';
import servicesIcon4 from '../images/serviceIcon/servicesIcon4.png';
import choose1 from '../images/choose/choose1.png';
import choose2 from '../images/choose/choose2.png';
import choose3 from '../images/choose/choose3.png';

import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import Slider from 'react-infinite-logo-slider';
import ParticlesBg from "particles-bg";
import HomeCarousel from "./homeCarousel";
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

export function Home() {
  const handleChange = (e) => {
    const pClass = e.target.parentElement;
    console.log(e.target);
    if (e.target.className === "article nochosen") {
      for (let i = 0; i < pClass.childNodes.length; i++) {
        pClass.childNodes[i].className = "article nochosen";
      }
      e.target.classList.remove("nochosen");
      e.target.classList.add("chosen");
    } else if (e.target.className === "article chosen") {
      e.target.className = "article nochosen";
    }
  };
  const serviceImage = {
    "services1.jpg": services1,
    "services2.jpg": services2,
    "services3.jpg": services3,
    "services4.jpg": services4
  };
  const serviceIcon = {
    "servicesIcon1.png": servicesIcon1,
    "servicesIcon2.png": servicesIcon2,
    "servicesIcon3.png": servicesIcon3,
    "servicesIcon4.png": servicesIcon4
  };
  const chooseIcon = {
    "choose1.png": choose1,
    "choose2.png": choose2,
    "choose3.png": choose3
  };
  
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      slidesToSlide: 3 // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      slidesToSlide: 2 // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1 // optional, default to 1.
    }
  };

  let config = {
    num: [4, 7],
    rps: 0.1,
    radius: [5, 40],
    life: [1.5, 3],
    v: [2, 3],
    tha: [-40, 40],
    alpha: [0.6, 0],
    scale: [.1, 0.4],
    position: "all",
    color: ["random", "#ff0000"],
    cross: "dead",
    // emitter: "follow",
    random: 15
  };

  if (Math.random() > 0.85) {
    config = Object.assign(config, {
      onParticleUpdate: (ctx, particle) => {
        ctx.beginPath();
        ctx.rect(
          particle.p.x,
          particle.p.y,
          particle.radius * 2,
          particle.radius * 2
        );
        ctx.fillStyle = particle.color;
        ctx.fill();
        ctx.closePath();
      }
    });
  }
  return (
    <div className="home">
      <ParticlesBg type="custom" config={config} bg={true} />

      <HomeCarousel />
      {/* <div className="homeNews"  style={{ backgroundImage: `url(${photoBg})` }}>
        <div className="homeNewsContent">
          <h1>Welcome to Perfect Interior Shop</h1>
          <p>At Perfect Interior Shop, we believe that your home is a reflection of your personality and style. Our mission is to help you create beautiful, functional spaces that you and your family will love for years to come. Whether you're looking to revamp a single room or undertake a complete home makeover, our experienced designers are here to guide you every step of the way.</p>
        </div>
        <Link to="">
          <button>
            Get Started
            <BsArrowRight style={{ marginLeft: "2%", color: "#CDA274" }} />
          </button>
        </Link>
      </div> */}
      <div className="homeOther">
        <div className="homePlans home-plan-service row">
          <div className="text-center">
            <h4 className="theme-color">OUR SERVICES</h4>
            <h3 className="mb-5">What We Can Offer You</h3>
          </div>
          {services.services
            .filter((services, index) => index < 3)
            .map((s, ind) => {
              return (
                <Col key={ind} className="mb-1 mt-1" xs={12} sm={6} md={4} lg={4}>
                  <Card className="card-bg" style={{borderRadius:"0"}}>
                    <Card.Img variant="top" src={serviceImage[s.service_image]} />
                    <Card.Body >
                      <div class="services__item__text__icon">
                        <img src={serviceIcon[s.service_icon]} />
                      </div>
                      <Card.Title style={{borderBottom : "1px solid"}}>{s.service_name}</Card.Title>
                      <Card.Text style={{ marginTop:"10%"}}>{s.service_content}</Card.Text>
                    </Card.Body>
                  </Card>
                </Col>
                // <div className="homePlan col-lg-3 col-sm-3 col-xs-12 mt-4" key={ind}>
                //   <h2>{s.service_name}</h2>
                //   <p>{s.service_content}</p>
                // </div>
              );
            })}
        </div>

        <div className="homePlans home-plans-choose row">
          <div className="text-center">
            <h3 className="mb-5">Why Choose Us?</h3>
          </div>
          {chooseUs.services
            .filter((services, index) => index < 3)
            .map((s, ind) => {
              return (
                <Col key={ind} className="mb-1 mar-top-5"  xs={12} sm={6} md={4} lg={4}>
                  <Card className="">
                    <div className="home-plans-choose-icon">
                      <img src={chooseIcon[s.service_icon]} alt="any3" className='w-36' />
                    </div>
                    <Card.Body>
                      <Card.Title className="text-bold-700">{s.service_name}</Card.Title>
                      <Card.Text>{s.service_content}</Card.Text>
                    </Card.Body>
                  </Card>
                </Col>
                // <div className="homePlan col-lg-3 col-sm-3 col-xs-12 mt-4" key={ind}>
                //   <h2>{s.service_name}</h2>
                //   <p>{s.service_content}</p>
                // </div>
              );
            })}
        </div>
        <div className="homeAboutUs row">
          
            <div className="hp-img col-xs-12 col-sm-12 col-md-6 col-lg-6">
              <img src={home1} alt="concept"></img>
            </div>
            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
              <div className="hp-subtext">
                <h2 className="app-text-primary text-center">Get Started Today</h2>
                <p className="text-center">Ready to turn your home into the space of your dreams? Contact us today to schedule a consultation with one of our expert designers.</p>
                <div className="callUs row text-center">
                  <div className="phoneNum col-lg-12 col-sm-12 col-xs-12">
                    <p className="h-icon">
                      <BsTelephone style={{ color: "#003c49" }}/>
                    </p>
                    <a href="tel: +(91) 7395970643"> <p> +(91) 7395970643 <br /> <span>Call Us Anytime</span></p></a> <br />
                  </div>
                  <Link to={`/contact`}  className="col-lg-12 col-sm-12 col-xs-12">
                    <button>
                      Get Free Estimate
                      <BsArrowRight style={{ marginLeft: "2%", color: "#fff" , fontSize: "25px"}} />
                    </button>
                  </Link>
                </div>
              </div>
            </div>
        </div>
        {/* <div className="callUs">
          <div className="phoneNum">
            <p className="h-icon">
              <BsTelephone />
            </p>
            <a href="tel: +994 (070) 883-37-38">
              <p>
                +994 (070) 883-37-38
                <br />
                <span>Call Us Anytime</span>
              </p>
            </a>
            <br />
          </div>

          <Link to={`/contact`}>
            <button>
              Get Free Estimate
              <BsArrowRight style={{ marginLeft: "2%", color: "#CDA274" }} />
            </button>
          </Link>
        </div> */}
        <div className="people-thoughts">
          <h3 className="app-text-primary mb-4">What People Think About Us</h3>
          <Carousel
            swipeable={true}
            draggable={true}
            showDots={true}
            responsive={responsive}
            ssr={true} // means to render carousel on server-side.
            infinite={true}
            autoPlaySpeed={1000}
            keyBoardControl={true}
            customTransition="all .5"
            transitionDuration={500}
            containerClass="carousel-container"
            removeArrowOnDeviceType={["tablet", "mobile"]}
            dotListClass="custom-dot-list-style"
            itemClass="carousel-item-padding-40-px"
          >
            {thoughts.people.map((req, ind) => {
              return (
                <div className="person-card">
                  <div className="person" key={ind}>
                    <div className="person-title">
                      <div>
                        {/* <img src={customer1} alt="customer"></img> */}
                      </div>
                      <p>
                        {req.fullname}
                        <br />
                        <span>{req.country}</span>
                      </p>
                    </div>
                    <p className="thought">{req.thoughts}</p>
                  </div>
                </div>
              );
            })}
          </Carousel>
          {/* <div className="people row">
            {thoughts.people.map((req, ind) => {
              return (
                <div className="person-card col-lg-4 col-sm-4 col-xs-12">
                  <div className="person" key={ind}>
                    <div className="person-title">
                      <div>
                        <img src={customer1} alt="customer"></img>
                      </div>
                      <p>
                        {req.fullname}
                        <br />
                        <span>{req.country}</span>
                      </p>
                    </div>
                    <p className="thought">{req.thoughts}</p>
                  </div>
                </div>
              );
            })}
          </div> */}
        </div>
        
        <div className="home-brands row">
          <Slider
              width="250px"
              duration={40}
              pauseOnHover={true}
              blurBorders={false}
              blurBoderColor={'#fff'}
          >
              <Slider.Slide>
                  <img src={brand1} alt="any" className='w-36' />
              </Slider.Slide>
              <Slider.Slide>
                  <img src={brand2} alt="any2" className='w-36' />
              </Slider.Slide>
              <Slider.Slide>
                  <img src={brand3} alt="any3" className='w-36' />
              </Slider.Slide>
              <Slider.Slide>
                  <img src={brand4} alt="any3" className='w-36' />
              </Slider.Slide>
          </Slider>
          {/* <div className="col-lg-2 col-sm-2 col-xs-6 home-brands-card">
            <img src={brand1} alt="brand"></img>
          </div>
          <div className="col-lg-2 col-sm-2 col-xs-6 home-brands-card">
            <img src={brand2} alt="brand"></img>
          </div>
          <div className="col-lg-2 col-sm-2 col-xs-6 home-brands-card">
            <img src={brand3} alt="brand"></img>
          </div>
          <div className="col-lg-2 col-sm-2 col-xs-6 home-brands-card">
            <img src={brand4} alt="brand"></img>
          </div>
          <div className="col-lg-2 col-sm-2 col-xs-6 home-brands-card">
            <img src={brand5} alt="brand"></img>
          </div> */}
        </div>
        <div className="container" style={{padding:0}}>
        <div className="home-experience row">
          <div className="home-experience-box ebr-r col-3">
          <CountUp className="h-year num" duration={4} end={12} />
            <p>Years Of Experience</p>
          </div>
          <div className="home-experience-box ebr-r col-3">
          <CountUp duration={4} className="h-sp num" end={85} />
            <p>Success Project</p>
          </div>
          <div className="home-experience-box ebr-r col-3">
          <CountUp duration={4} className="h-ap num" end={15} />
            <p>Active Project</p>
          </div>
          <div className="home-experience-box  col-3">
          <CountUp duration={4} className="h-cust num" end={95} />
            <p>Happy Customers</p>
          </div>
        </div>
      </div>
        <div className="homeProjects mt-4">
          <h3>Follow Our Projects</h3>
          <p>
            It is a long established fact that a reader will be distracted by
            the of readable content of a page lookings at its layouts.
          </p>
          <div className="hp-list row">
            <div className="hp-project col-lg-6 col-sm-6 col-xs-12">
              <div className="hp-project-card">
                <div className="hp-pro-img">
                  <img src={hPoject1} alt="project"></img>
                </div>
                <div className="hp-pro-detail">
                  <div className="hp-pro-info">
                    <p className="hp-prj-title">Rack Innovation</p>
                    <p className="hp-prj-path">Decor / Architecture</p>
                  </div>
                  <div className="hp-pro-btn">
                    <Link to={`/projects`}>
                      <button>
                        <IoIosArrowForward />
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>

            <div className="hp-project col-lg-6 col-sm-6 col-xs-12">
              <div className="hp-project-card">
                <div className="hp-pro-img">
                  <img src={hhProject2} alt="project"></img>
                </div>
                <div className="hp-pro-detail">
                  <div className="hp-pro-info">
                    <p className="hp-prj-title">Modular Kitchen</p>
                    <p className="hp-prj-path">Decor / Architecture</p>
                  </div>
                  <div className="hp-pro-btn">
                    <Link to={`/projects`}>
                      <button>
                        <IoIosArrowForward />
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>

            <div className="hp-project col-lg-6 col-sm-6 col-xs-12">
              <div className="hp-project-card">
                <div className="hp-pro-img">
                  <img src={hhProject3} alt="project"></img>
                </div>
                <div className="hp-pro-detail">
                  <div className="hp-pro-info">
                    <p className="hp-prj-title">Pooja Room</p>
                    <p className="hp-prj-path">Decor / Architecture</p>
                  </div>
                  <div className="hp-pro-btn">
                    <Link to={`/projects`}>
                      <button>
                        <IoIosArrowForward />
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>

            <div className="hp-project col-lg-6 col-sm-6 col-xs-12">
              <div className="hp-project-card">
                <div className="hp-pro-img">
                  <img src={hhProject4} alt="project"></img>
                </div>
                <div className="hp-pro-detail">
                  <div className="hp-pro-info">
                    <p className="hp-prj-title">Pooja Room</p>
                    <p className="hp-prj-path">Decor / Architecture</p>
                  </div>
                  <div className="hp-pro-btn">
                    <Link to={`/projects`}>
                      <button>
                        <IoIosArrowForward />
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <div className="articleNews">
        <h1>Articles & News</h1>
        <p>
          It is a long established fact that a reader will be distracted by the
          of readable content of a page when lookings at its layouts
        </p>
        <div className="articles row">
          <div className="article nochosen col-lg-4 col-sm-4 col-xs-12" onClick={handleChange}>
            <div className="article-header">
              <img src={hArticle1} alt="article"></img>
            </div>
            <div className="article-content">
              <p>Let’s Get Solution For Building Construction Work</p>
              <div className="ac-detail">
                <p className="ac-date">3 March 2023</p>
                <Link to={`/blog-details`}>
                  <button>
                    <IoIosArrowForward />
                  </button>
                </Link>
              </div>
            </div>
          </div>
          <div className="article nochosen col-lg-4 col-sm-4 col-xs-12" onClick={handleChange}>
            <div className="article-header">
              <img src={hArticle2} alt="article"></img>
            </div>
            <div className="article-content">
              <p>Let’s Get Solution For Building Construction Work</p>
              <div className="ac-detail">
                <p className="ac-date">3 March 2023</p>
                <Link to={`/blog-details`}>
                  <button>
                    <IoIosArrowForward />
                  </button>
                </Link>
              </div>
            </div>
          </div>
          <div className="article nochosen col-lg-4 col-sm-4 col-xs-12" onClick={handleChange}>
            <div className="article-header">
              <img src={hArticle3} alt="article"></img>
            </div>
            <div className="article-content">
              <p>Let’s Get Solution For Building Construction Work</p>
              <div className="ac-detail">
                <p className="ac-date">3 March 2023</p>
                <Link to={`/blog-details`}>
                  <button>
                    <IoIosArrowForward />
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div> */}

    </div>
  );
}
