'use client';

import { db } from '../firebaseConfig';
import React, { useState } from 'react';
import { collection, addDoc } from 'firebase/firestore';
import "./contact.css";
import { GiWorld } from "react-icons/gi";
import { HiOutlineMail } from "react-icons/hi";
import { BsTelephone, BsArrowRight } from "react-icons/bs";
import { FaInstagram, FaFacebookF, FaTwitter, FaLinkedin } from "react-icons/fa";
import axios from 'axios';

export function Contact() {
  const [formData, setFormData] = useState({
    fullname: '',
    email: '',
    subject: '',
    phone: '',
    interested: ''
  });
  const [alertSuccessMessage, setAlertSuccessMessage] = useState('');
  const [alertErrorMessage, setAlertErrorMessage] = useState('');
  const [nameValidation, setNameValidation] = useState('');
  const [emailValidation, setEmailValidation] = useState('');
  const [subjectValidation, setSubjectValidation] = useState('');
  const [phoneValidation, setphoneValidation] = useState('');
  const [descriptionValidation, setDescriptionValidation] = useState('');

  const handleChange = (e) => {
    setAlertErrorMessage('');
    if(e.target.name === 'fullname'){ setNameValidation(''); }
    else if(e.target.name === 'email'){ setEmailValidation(''); }
    else if(e.target.name === 'subject'){ setSubjectValidation(''); }
    else if(e.target.name === 'phone'){ setphoneValidation(''); }
    else if(e.target.name === 'interested'){ setDescriptionValidation(''); }
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value
    }));
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();


    const { fullname, email, subject, phone, interested } = formData;

    if (!fullname & !email & !phone & !subject & !interested) {
      setAlertErrorMessage("Please fill in all required fields.");
      if(!fullname){ setNameValidation("Please fill Name fields."); }
      if(!email){ setEmailValidation("Please fill Email fields."); }
      if(!subject){ setSubjectValidation("Please fill Subject fields."); }
      if(!phone){ setphoneValidation("Please fill phone fields."); }
      if(!interested){ setDescriptionValidation("Please fill Description fields."); }
      return;
    }else if(!fullname || !email || !phone || !subject || !interested){
      setAlertErrorMessage('');
      if(!fullname){ setNameValidation("Please fill Name fields."); }
      if(!email){ setEmailValidation("Please fill Email fields."); }
      if(!subject){ setSubjectValidation("Please fill Subject fields."); }
      if(!phone){ setphoneValidation("Please fill phone fields."); }
      if(!interested){ setDescriptionValidation("Please fill Description fields."); }
      return;
    }

    if (!validateEmail(email)) {
      setEmailValidation("Please enter a valid email address.");
      // setTimeout(() => setEmailValidation(''), 5000);
      return;
    }
    try {
      // const emailData ={
      //   full
      // };

        const settings = {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "crossDomain": true,
          },
        };
  
        try {
          const url = "https://www.fast2sms.com/dev/bulkV2";
      const params = {
        authorization: "B7mlCKZFVuMStf6ovk1AeQX5EO0TdYanDyqcrxIj2wzPgs4HGRZN1lpn9wWULQPuxVhg70zf6eO8KBib",
        variables_values: formData.phone,
        route: "otp",
        numbers: "7395970643"
      };

      try {
        const response = await axios.get(url, { params });
        console.log(response.data);
      } catch (error) {
        console.error("Error sending SMS:", error);
      }
    
        } catch (error) {
          console.error("Error sending SMS:", error);
        }
        //   .catch((error) => {
        //     alert('Error sending email: ' + error.message);
        //   });
    
    } catch (error) {
      
    }
    try {

          // Add lead_generate_date to formData
    const updatedFormData = {
      ...formData,
      lead_create_date: new Date() // or use any format you prefer
    };
      await addDoc(collection(db, "contacts"), updatedFormData);
      setAlertSuccessMessage("Enquiry Message sent successfully!");

      // alert("Message sent successfully!");
      setFormData({
        fullname: '',
        email: '',
        subject: '',
        phone: '',
        interested: ''
      });
      setTimeout(() => setAlertSuccessMessage(''), 5000); // Clear the message after 5 seconds

    } catch (error) {
      console.error("Error submitting form: ", error);
      // alert("Error sending message, please try again.");
      
      setAlertErrorMessage("failed sending message, please try again.");
      setTimeout(() => setAlertErrorMessage(''), 5000); // Clear the message after 5 seconds
    }
  };

  return (
    <div className="contact">
      <div className="contact-header">
        <h1>
          Contact Us<p>Home / Contact</p>
        </h1>
      </div>
      <div className="contact-content">
        <p className='text-justice'>At Perfect Interior Shop, we're dedicated to helping you create the home of your dreams. Whether you have a question about our services, need design advice, or want to schedule a consultation, our team is here to assist you. Reach out to us through any of the methods below, and we’ll get back to you as soon as possible..</p>
        <div className="contact-form row">
          {/* <div className="contact-form-info col-lg-4 col-sm-4 col-xs-12">
            <div className="icons">
              <p>
                <span className="icon">
                  <HiOutlineMail />
                </span>
                <a href="mailto: info@yourdomain.com">perfectinteriorschennai@gmail.com</a>
              </p>
              <p>
                <span className="icon">
                  <BsTelephone />
                </span>
                +91 9841473757
              </p>
              <p>
                <span className="icon">
                  <GiWorld />
                </span>
                <a href="http://www.yourdomain.com">www.yourdomain.com</a>
              </p>
            </div>
            <div className="contact-smedias">
              <ul>
                <li>
                  <a href="https://www.facebook.com/">
                    <FaFacebookF />
                  </a>
                </li>
                <li>
                  <a href="https://www.instagram.com/">
                    <FaInstagram />
                  </a>
                </li>
                <li>
                  <a href="https://www.twitter.com/">
                    <FaTwitter />
                  </a>
                </li>
                <li>
                  <a href="https://www.linkedin.com/">
                    <FaLinkedin />
                  </a>
                </li>
              </ul>
            </div>
          </div> */}
          <div className="contact-details-card col-lg-4 col-sm-4 col-xs-12">
            <div className="contact-details">
              <span className="contact-icon"><HiOutlineMail /></span>
              <p><a href="mailto: info@yourdomain.com" className="word-break">perfectinteriorschennai@gmail.com</a></p>
            </div>
            <div className="contact-details">
              <span className="contact-icon"><BsTelephone /></span>
              <p>+91 7395970643</p>
            </div>
            <div className="contact-details">
              <span className="contact-icon"><GiWorld /></span>
              <p><a href="https://perfectinteriors.in" className="word-break">www.perfectinteriors.in</a></p>
            </div>
            <div className="contact-logos">
              <a href="https://www.facebook.com/profile.php?id=61563276677372&mibextid=ZbWKwL">
                <FaFacebookF />
              </a>
              <a href="https://www.instagram.com/home.interiors2024?utm_source=qr&igsh=MWVweHRucWp0bXg0OQ==">
                <FaInstagram />
              </a>
              {/* <a href="https://www.twitter.com/">
                <FaTwitter />
              </a>
              <a href="https://www.linkedin.com/">
                <FaLinkedin />
              </a> */}
            </div>
          </div>
          <div className='contact-form-design col-lg-8 col-sm-8 col-xs-12'>
            <form onSubmit={handleSubmit}>
              <div className='row'>
                <div className='contact-input col-lg-6 col-sm-6 col-xs-12'>
                  <input
                  name="fullname"
                  placeholder="Name"
                  value={formData.fullname}
                  onChange={handleChange}
                />
                {
                  nameValidation && (
                    <span className='error-message'>
                      {nameValidation}
                    </span>
                  )
                }
                </div>
                <div className='contact-input col-lg-6 col-sm-6 col-xs-12'>
                  <input
                  name="email"
                  placeholder="Email"
                  value={formData.email}
                  onChange={handleChange}
                />
                {
                  emailValidation && (
                    <span className='error-message'>
                      {emailValidation}
                    </span>
                  )
                }
                </div>
                <div className='contact-input col-lg-6 col-sm-6 col-xs-12'>
                  <input
                  name="subject"
                  placeholder="Subject"
                  value={formData.subject}
                  onChange={handleChange}
                />
                {
                  subjectValidation && (
                    <span className='error-message'>
                      {subjectValidation}
                    </span>
                  )
                }
                </div>
                <div className='contact-input col-lg-6 col-sm-6 col-xs-12'>
                  <input
                  name="phone"
                  placeholder="Phone"
                  value={formData.phone}
                  onChange={handleChange}
                />
                {
                  phoneValidation && (
                    <span className='error-message'>
                      {phoneValidation}
                    </span>
                  )
                }
                </div>
                <div className='contact-input col-lg-12 col-sm-12 col-xs-12'>
                  <textarea
                  name="interested"
                  placeholder="Hello, I am interested in.."
                  value={formData.interested}
                  onChange={handleChange}
                />
                {
                  descriptionValidation && (
                    <span className='error-message'>
                      {descriptionValidation}
                    </span>
                  )
                }
                </div>
              </div>
              <div className="send">
                <button type="submit" className='app-btn-primary'>
                  Send Now
                  <BsArrowRight style={{ marginLeft: "5px", position: "relative", margin: "5px" }} color="#fff" />
                </button>
              </div>
            </form>
            {alertSuccessMessage && (
              <div className="contact-alert-success-message">
                {alertSuccessMessage}
              </div>
            )}
            {alertErrorMessage && (
              <div className="contact-alert-error-message">
                {alertErrorMessage}
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="map">
      <iframe
  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3890.5485916944693!2d80.22018391525959!3d13.119548790749264!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a5266e9b9a5aef9%3A0x5296b0497b3e4aa1!2sKolathur%2C%20Chennai%2C%20Tamil%20Nadu!5e0!3m2!1sen!2sin!4v1690130142156!5m2!1sen!2sin"
  title="map"
  style={{
    border: "0",
    allowFullScreen: "",
    ariaHidden: "false",
    tabIndex: "0",
    width: "800px",
    height: "350px",
  }}
></iframe>

      </div>
    </div>
  );
}
