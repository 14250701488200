import React, { useEffect, useState } from 'react';
import './TableComponent.css'; // Adjust the path if needed
import { collection, getDocs, orderBy } from 'firebase/firestore';
import { db } from '../firebaseConfig';

const TableComponent = () => {

    const [contacts, setContacts] = useState([]);
    const [error, setError] = useState(null);
  
    useEffect(() => {

      fetchContacts();
    }, [contacts]);

    const fetchContacts = async () => {
        try {
          const contactsCollection = collection(db, 'contacts');
          const q = orderBy('lead_create_date', 'asc');
          const querySnapshot = await getDocs((contactsCollection));
          
          const contactsData = querySnapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data()
          }));
          
          setContacts(contactsData);
        } catch (err) {
            console.log(err);
            
          setError(err.message);
        }
      };
  
  return (
    <section className="ftco-section" >
      <div className="container" >
        <div className="row justify-content-center">
          <div className="col-md-6 text-center mb-5">
            <h2 className="heading-section"  style={{marginTop:"30%"}}>Lead Message</h2>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="table-wrap">
            {contacts && contacts.length>0?
              <table className="table table-bordered table-dark table-hover">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Name</th>
                    <th>Mobile</th>
                    <th>Email</th>
                  </tr>
                </thead>
                <tbody>
                    {contacts.map((message, index)=>
                  <tr>
                    <th scope="row">{index+1}</th>
                    <td>{message.fullname}</td>
                    <td>{message.phone}</td>
                    <td>{message.email}</td>
                  </tr>
                    )}

                </tbody>
              </table>
              :<p>There is no lead messages found</p>
                }
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default TableComponent;
