import React, { useState } from "react";
import { Link } from "react-router-dom";
import logo from "../images/logo.png";
import "./header.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { Navbar, Nav } from "react-bootstrap";

export function Header() {
  const [expanded, setExpanded] = useState(false);

  const handleNavLinkClick = () => {
    setExpanded(false);
  };

  return (
    <Navbar expand="lg" expanded={expanded} className="header">
      <div className="container-fluid">
        <Navbar.Brand className="header-brand">
          <Link className="d-flex align-items-center" to="/">
            <div className="header-logo">
              <img src={"./perfect-interior.png"} alt="logo" className="img-fluid"></img>
            </div>
            <div className="header-text">
              <p>Perfect Interior</p>
            </div>
          </Link>
        </Navbar.Brand>
        <Navbar.Toggle
          aria-controls="basic-navbar-nav"
          onClick={() => setExpanded(expanded ? false : "expanded")}
        />
        <Navbar.Collapse id="basic-navbar-nav" className="justify-content-center">
          <Nav className="header-pages">
            <Nav.Link as={Link} to="/" onClick={handleNavLinkClick}>
              Home
            </Nav.Link>
            {/* <Nav.Link as={Link} to={`team`}>Team</Nav.Link> */}
            <Nav.Link as={Link} to="/services" onClick={handleNavLinkClick}>
              Services
            </Nav.Link>
            <Nav.Link as={Link} to="/projects" onClick={handleNavLinkClick}>
              Projects
            </Nav.Link>
            {/* <Nav.Link as={Link} to={`/blog`}>Blog</Nav.Link> */}
            <Nav.Link as={Link} to="/contact" onClick={handleNavLinkClick}>
              Contact
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </div>
    </Navbar>
  );
}
