import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyAH_o4Lf5AciA3myXmZG5ud_IIWwTa4bzA",
  authDomain: "perfect-interior-design.firebaseapp.com",
  projectId: "perfect-interior-design",
  storageBucket: "perfect-interior-design.appspot.com",
  messagingSenderId: "851774505022",
  appId: "1:851774505022:web:c9c6a587f523437e6e516f",
  measurementId: "G-ZG3Z4ZPE7P"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firestore
export const db = getFirestore(app);

console.log("Firestore initialized: ", db);
