import { Carousel } from 'react-bootstrap';
import React from 'react';
import photoBg from "../images/backgroung/homeBg.jpg";
import { Link } from "react-router-dom";
import { BsArrowRight, BsTelephone } from "react-icons/bs";
import "./home.css";
import "./homeResponsive.css";

function HomeCarousel() {
  return (
    <Carousel>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src={photoBg}
          alt="First slide"
        />
        <Carousel.Caption>
          <h3 className='carosuel-text'>Transform Your Living Space</h3>
          <p className='carosuel-text-in'>"Discover our exclusive range of contemporary furniture and decor. Whether it's a cozy sofa or a chic coffee table, we have everything you need to make your home truly yours."</p>
          {/* <Link to="">
            <button className='carousel-button'>
              Get Started
              <BsArrowRight style={{ marginLeft: "2%", color: "#CDA274" }} />
            </button>
          </Link> */}
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src={photoBg}
          alt="First slide"
        />
        <Carousel.Caption>
          <h3 className='carosuel-text'>Elegant and Functional Designs</h3>
          <p className='carosuel-text-in'>"Our expertly curated collections bring together elegance and functionality. From modern minimalist to timeless classics, find the perfect pieces to complement your style.</p>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src={photoBg}
          alt="First slide"
        />
        <Carousel.Caption>
          <h3 className='carosuel-text'>Custom Interiors Just for You</h3>
          <p className='carosuel-text-in'>
            " At Perfect Interior Shop, we offer personalized interior design solutions. Let our experts help you create a space that reflects your unique taste and lifestyle."
          </p>
        </Carousel.Caption>
      </Carousel.Item>
    </Carousel>
  );
}

export default HomeCarousel;