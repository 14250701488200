import "./projectCard.css";
import { Link } from "react-router-dom";
import { IoIosArrowForward } from "react-icons/io";
import project1 from "../images/custom/30ab81b3-a062-4f42-9087-065f17943945.jpg";
import project2 from "../images/custom/30ab81b3-a062-4f42-9087-065f17943945.jpg";
import project3 from "../images/custom/30ab81b3-a062-4f42-9087-065f17943945.jpg";
import LivingRoom from "../images/project/living-room.jpg";
import ModularKitchen from "../images/project/modular-kitchen.jpg";
import PoojaRoom from "../images/project/pooja-room.jpg";
import TVWallMountingUnit from "../images/project/tvmounting-unit.jpg";
import DiningHall from "../images/project/dining-hall.jpeg";
import DesignedFalseCeiling from "../images/project/ceiling-designs.jpg";
import AluminiumPartitions from "../images/project/aluminium-glassdoor.jpg";
import GypsumPartitions from "../images/project/gypsum-board.jpg";

export function ProjectCard(props) {
  const projectImage = {
    "living-room.jpg": LivingRoom,
    "modular-kitchen.jpg": ModularKitchen,
    "pooja-room.jpg": PoojaRoom,
    "tvmounting-unit.jpg": TVWallMountingUnit,
    "dining-hall.jpeg": DiningHall,
    "ceiling-designs.jpg": DesignedFalseCeiling,
    "aluminium-glassdoor.jpg": AluminiumPartitions,
    "gypsum-board.jpg": GypsumPartitions
  };
  return(
    <div className="op-project  d-flex flex-column h-100">
      <div className="op-pro-img">
        <img src={projectImage[props.props.project_image]} alt="project"></img>
      </div>
      <div className="op-pro-detail">
        <div className="op-pro-info">
          <p className="op-prj-title">{props.props.project_name}</p>
          <p className="op-prj-path">{props.props.content}</p>
        </div>
        <div className="op-pro-btn">
          <Link to={`/projects`}>
            <button>
              <IoIosArrowForward />
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
}
